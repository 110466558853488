import GifComponent from "../components/Gif";
import "react-awesome-button/dist/styles.css";
export default function Scene(onPress, onPress2 = () => {}) {
  return (
    <>
      <>
        <div style={{ cursor: "pointer" }} onClick={onPress}>
          <GifComponent
            marginTop={100}
            id="dudu_heart_1"
            text="Press on my belly again.."
          />
        </div>
        <GifComponent
          marginTop={100}
          marginLeft={"6%"}
          id="bubu_confused"
          textPosition="right"
        />
      </>
    </>
  );
}
