import React, { useState } from "react";
import GifComponent from "./components/Gif";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import Scene1 from "./screens/Scene1";
import Scene2 from "./screens/Scene2";
import Scene3 from "./screens/Scene3";
import Scene4 from "./screens/Scene4";
import Scene5 from "./screens/Scene5";
import Scene6 from "./screens/Scene6";
import Scene7 from "./screens/Scene7";
import Scene8 from "./screens/Scene8";
import Scene9 from "./screens/Scene9";
import Scene10 from "./screens/Scene10";
import Scene11 from "./screens/Scene11";
import Scene12 from "./screens/Scene12";
import Scene13 from "./screens/Scene13";
import Scene14 from "./screens/Scene14";
import Scene15 from "./screens/Scene15";
import Scene16 from "./screens/Scene16";
import Scene17 from "./screens/Scene17";
import Scene18 from "./screens/Scene18";
import Scene19 from "./screens/Scene19";
import Scene20 from "./screens/Scene20";
import Scene21 from "./screens/Scene21";
import Scene22 from "./screens/Scene22";
import Scene23 from "./screens/Scene23";
import haidilao from "./gifs/haidilao.jpg";

const style = {
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "lightblue",
};
const scenes = {
  1: Scene1,
  2: Scene2,
  3: Scene3,
  4: Scene4,
  5: Scene5,
  6: Scene6,
  7: Scene7,
  8: Scene8,
  9: Scene9,
  10: Scene10,
  11: Scene11,
  12: Scene12,
  13: Scene13,
  14: Scene14,
  15: Scene15,
  16: Scene16,
  17: Scene17,
  18: Scene18,
  19: Scene19,
  20: Scene20,
  21: Scene21,
  22: Scene22,
  23: Scene23,
};

function App() {
  const [currentScene, setCurrentScene] = useState(1);
  return (
    <div
      style={
        currentScene === 23
          ? {
              ...style,
              backgroundImage: `url(${haidilao})`,
              backgroundSize: "cover",
            }
          : { ...style }
      }
    >
      {currentScene === 21 ? (
        <Scene21
          onPress={() => {
            setCurrentScene(currentScene + 1);
          }}
        />
      ) : (
        scenes[currentScene](() => {
          if (currentScene === scenes.length) {
            setCurrentScene(1);
          } else setCurrentScene(currentScene + 1);
        })
      )}
    </div>
  );
}

export default App;
