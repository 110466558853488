import GifComponent from "../components/Gif";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
export default function Scene(onPress, onPress2 = () => {}) {
  return (
    <>
      <>
        <div>
          <GifComponent
            marginTop={100}
            width={350}
            id="dudu_heart_2"
            text="I was wondering.."
          />
        </div>
        <GifComponent
          marginTop={100}
          marginLeft={"6%"}
          id="bubu_excited"
          textPosition="right"
        />
      </>
      <div style={{ position: "absolute", bottom: "10%" }}>
        <AwesomeButton
          style={{
            "--button-primary-color": "#dba490",
            "--button-primary-color-dark": "#481f1b",
            "--button-primary-color-light": "#4b1616",
            "--button-primary-color-hover": "#fcfcfc",
            "--button-primary-color-active": "#fcfcfc",
            "font-weight": "bold",
            "font-size": "17px",
            color: "white",
          }}
          onPress={onPress}
        >
          Next
        </AwesomeButton>
      </div>
    </>
  );
}
