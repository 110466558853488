import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
export default function Gif({
  id,
  width = 300,
  text = "",
  textPosition = "left",
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  color = "black",
}) {
  const { windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < 768;
  return isMobile ? (
    <div style={{ marginTop, marginLeft, marginRight, color }}>
      {textPosition === "left" && text}
      <div>
        <img src={`gifs/${id}.gif`} alt="Tenor GIF" width={width / 2} />
      </div>
      {textPosition === "right" && text}
    </div>
  ) : (
    <>
      {textPosition === "left" &&
        (text.length < 15 ? <h2>{text}</h2> : <h3>{text}</h3>)}
      <div style={{ marginTop, marginLeft, marginRight }}>
        <img src={`gifs/${id}.gif`} alt="Tenor GIF" width={width} />
      </div>
      {textPosition === "right" &&
        (text.length < 10 ? <h2>{text}</h2> : <h3>{text}</h3>)}
    </>
  );
}
