import GifComponent from "../components/Gif";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import valentine from "../gifs/BeMyValentine.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useState } from "react";

export default function Scene({ onPress, onPress2 = () => {} }) {
  const { windowWidth } = useWindowDimensions();
  const [leftMargin, setLeftMargin] = useState(0);
  const [noPressed, setNoPressed] = useState(false);
  return (
    <>
      <div style={{ position: "absolute", top: "10%" }}>
        <img
          src={valentine}
          width={windowWidth < 768 ? 300 : windowWidth / 3}
          alt="Logo"
        />
      </div>
      <>
        <GifComponent marginTop={100} width={350} id="dudu_flower" />
        <GifComponent
          marginTop={100}
          marginLeft={"6%"}
          id="bubu_excited"
          textPosition="right"
        />
      </>
      {noPressed && (
        <div
          style={{
            position: "absolute",
            bottom: "5%",
          }}
        >
          <div style={{ left: leftMargin + 20, position: "relative" }}>
            <GifComponent marginTop={100} width={350} id="dudu_kick" />
          </div>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "10%",
          overflow: "hidden",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <AwesomeButton
          style={{
            "--button-primary-color": "#10e880",
            "--button-primary-color-dark": "#14824d",
            "--button-primary-color-light": "#4b1616",
            "--button-primary-color-hover": "#fcfcfc",
            "--button-primary-color-active": "#fcfcfc",
            "font-weight": "bold",
            "font-size": "17px",
            color: "white",
            marginRight: "40px",
          }}
          onPress={onPress}
        >
          Yes
        </AwesomeButton>
        <AwesomeButton
          style={{
            "--button-primary-color": "#e34646",
            "--button-primary-color-dark": "#821414",
            "--button-primary-color-light": "#4b1616",
            "--button-primary-color-hover": "#fcfcfc",
            "--button-primary-color-active": "#fcfcfc",
            "font-weight": "bold",
            "font-size": "17px",
            color: "white",
            left: leftMargin,
          }}
          onPress={() => {
            setNoPressed(true);
            setTimeout(() => {
              setNoPressed(false);
              setLeftMargin(leftMargin + windowWidth / 6);
            }, 1000);
          }}
        >
          No
        </AwesomeButton>
      </div>
    </>
  );
}
