import GifComponent from "../components/Gif";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
export default function Scene(onPress, onPress2 = () => {}) {
  return (
    <>
      <div style={{ fontSize: "25px", fontWeight: "bold" }}>
        <GifComponent
          marginTop={100}
          width={350}
          id="bubu_dudu_dance"
          text="Now let's go to Haidilao!!!"
          color="white"
        />
      </div>
    </>
  );
}
